<template>
  <div class="Header block">
    <div class="container">
        <div class="column logoColumn">
            <router-link to="/"><a><img src="/meta/36x36.png" srcset="/meta/72x72.png 2x" alt=""></a></router-link>
        </div>
        <div class="hamburger" @click="slide_nav">
          <svg xmlns="http://www.w3.org/2000/svg" width="37" height="28" viewBox="0 0 37 28">
            <g id="Group_140" data-name="Group 140" transform="translate(1680 -3272)">
              <rect id="Rectangle_208" data-name="Rectangle 208" width="37" height="4" transform="translate(-1680 3272)" fill="#FFF"/>
              <rect id="Rectangle_207" data-name="Rectangle 207" width="37" height="4" transform="translate(-1680 3284)" fill="#FFF"/>
              <rect id="Rectangle_206" data-name="Rectangle 206" width="37" height="4" transform="translate(-1680 3296)" fill="#FFF"/>
            </g>
          </svg>
        </div>
        <div class="column menuColumn desktopColumn CustomFont">
            <div class="headerrow">
                <div class="column navMenu">
                    <nav>
                        <ul>
                            <li v-for="(item, index) in menuitems" :key="index">
                              <a v-if="item.type=='external'" :href="item.page">{{item.title}}</a>
                              <span v-else @click="set_page(item)">{{item.title}}</span>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="column menuColumn mobileColumn"  :class="{'ShowMenu':show_navigation}">
          <div @click="slide_nav" class="closeNav">
              <svg id="icon_times" viewBox="0 0 100 100" width="100%" height="100%">
                <path fill="#FFF" d="M100,80.4713805 C100,82.7160494 99.2143659,84.624018 97.6430976,86.1952862 L86.1952862,97.6430976 C84.624018,99.2143659 82.7160494,100 80.4713805,100 C78.2267116,100 76.318743,99.2143659 74.7474747,97.6430976 L50,72.8956229 L25.2525253,97.6430976 C23.681257,99.2143659 21.7732884,100 19.5286195,100 C17.2839506,100 15.375982,99.2143659 13.8047138,97.6430976 L2.35690236,86.1952862 C0.785634119,84.624018 0,82.7160494 0,80.4713805 C0,78.2267116 0.785634119,76.318743 2.35690236,74.7474747 L27.1043771,50 L2.35690236,25.2525253 C0.785634119,23.681257 0,21.7732884 0,19.5286195 C0,17.2839506 0.785634119,15.375982 2.35690236,13.8047138 L13.8047138,2.35690236 C15.375982,0.785634119 17.2839506,0 19.5286195,0 C21.7732884,0 23.681257,0.785634119 25.2525253,2.35690236 L50,27.1043771 L74.7474747,2.35690236 C76.318743,0.785634119 78.2267116,0 80.4713805,0 C82.7160494,0 84.624018,0.785634119 86.1952862,2.35690236 L97.6430976,13.8047138 C99.2143659,15.375982 100,17.2839506 100,19.5286195 C100,21.7732884 99.2143659,23.681257 97.6430976,25.2525253 L72.8956229,50 L97.6430976,74.7474747 C99.2143659,76.318743 100,78.2267116 100,80.4713805 Z"></path>
              </svg>
            </div>
            <div class="headerrow">
                <div class="column navMenu CustomFont">
                    <nav>
                        <ul>
                           <li v-for="(item, index) in menuitems" :key="index">
                              <a v-if="item.type=='external'" :href="item.page">{{item.title}}</a>
                              <span v-else @click="set_page(item)">{{item.title}}</span>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  props: {},
  data() {
    return {
      navigation: false,
      menuitems:[
        {title:"Vrijkaarten", page:"/aanvragen", type:"internal"},
        {title:"Educatieve pakketten", page:"/aanvragen", type:"internal"},
        {title:"Gratis magazine", page:"/magazine/", type:"external"},
        {title:"Nieuwsbrief", page:"nieuwsbrief", type:"internal"},
        {title:"Contact", page:"contact", type:"internal"},
        ]
    }
  },
  computed: {
    ...mapGetters([
      'get_config',
      'get_window',
      'get_selected_location'
    ]),
    show_navigation() {
      return this.navigation && this.get_window.is_mobile
    },
  },
  methods: {
    slide_nav() {
      this.navigation = !this.navigation;
    },
    set_page(item) {
      var path = item.page == 'locations' ? '/' : item.page;
      this.navigation = false;
      if(item.type == "external"){
        window.location = item.page;
      }else{
        this.$router.push({path: path});
      }
    }
  }
}
</script>
