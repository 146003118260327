import Layout from './views/Layout.vue'
import HoeWerktHet from './views/HoeWerktHet.vue'
import VeelgesteldeVragen from './views/VeelgesteldeVragen.vue'
import Contact from './views/Contact.vue'
import NotFound from './views/NotFound.vue'
import Locations from './views/Locations.vue'
import Info from './views/Info.vue'
import Error from './views/Error.vue'
import Nieuwsbrief from './views/Nieuwsbrief.vue'
import Aanvragen from './views/Aanvragen.vue'

export const routes = [{
    path: '/',
    component: Layout,
    children: [{
        path: '/',
        component: Locations,
        meta:{skip_history:0}
    },{
        path: '/info',
        component: Info,
        meta:{skip_history:0}
    },{
        path: '/aanvragen',
        component: Aanvragen,
        meta:{skip_history:0}
    },{
        path: '/error',
        component: Error,
        meta:{skip_history:1}
    },{
        path: '/hoe-werkt-het',
        component: HoeWerktHet,
        meta:{skip_history:1}
    },{
        path: '/veelgestelde-vragen',
        component: VeelgesteldeVragen,
        meta:{skip_history:1}
    },{
        path: '/contact',
        component: Contact,
        meta:{skip_history:1}
    },{
        path: '/nieuwsbrief',
        component: Nieuwsbrief,
        meta:{skip_history:1}
    },{
        path: '*',
        component: NotFound,
        meta:{skip_history:1}
    }]
}];