<template>
  <div>
    <slider animation="fade" :stopOnHover="true" :interval="5000" height="19vw" :indicators="indicators">
    <slider-item
    v-for="(i, index) in list"
    :key="index"
    >
    <a :href="i.url" :target="i.target"><img :src="'images/headers/'+i.image+'.jpg'" :srcset="'images/headers/'+i.image+'.jpg 1x, images/headers/'+i.image+'@2x.jpg 2x'" alt="" class="header-image"></a>
    </slider-item>
    </slider>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Banner',
  props: {
    image: String
  },
  data() {
    return {
      indicators: 'center'
    }
  },
  created () {
    var me = this;
    window.onresize = function(){
      me.indicators = window.innerWidth > 800 ? 'center':false;
    };
  },
  computed: {
    ...mapGetters([
      'get_config',
      'get_selected_location'
    ]),
    list() {
      const headersDB = [
        [5, '00_algemeen-ss2024',''],
        [1, 'airborne_museum','1022'],
        [1, 'amsterdam_dungeon','1107'],
        [1, 'avifauna','4558'],
        [2, 'billybird_park_hemelrijk','1030'],
        [1, 'body_worlds_amsterdam','1032'],
        [1, 'diergaarde_blijdorp','2613'],
        [1, 'dolfinarium','1053'],
        [1, 'hellendoorn','4560'],
        [1, 'julianatoren','3710'],
        [1, 'lego_discovery_center','3383'],
        [2, 'madame_tussauds','1075'],
        [1, 'museon_-__omniversum','1078'],
        [2, 'recreatiepark_linnaeushof_','3711'],
        [1, 'rederij_lovers','1074'],
        [1, 'ripleys_believe_it_or_not','1091'],
        [1, 'sea_life_scheveningen','3382'],
        [2, 'slagharen','1026'],
        [1, 'speelpark_oud_valkeveen','1099'],
        [1, 'this_is_holland','1108'],
        [1, 'wildlands_adventure_zoo','3456'],
        [1, 'adventure_valley_landgraaf','2861'],
        [1, 'adventure_valley_zoetermeer','4567'],
        [1, 'dordrechts_museum','4563'],
        [1, 'fun_forest_almerel','1059'],
        [1, 'fun_forest_amsterdam','3712'],
        [1, 'fun_forest_rotterdam','3713'],
        [1, 'fun_forest_venlo','3714'],
        [1, 'hof_van_nederland','4561'],
        [1, 'huis_van_gijn','4562'],
        [1, 'icekar_rucphen','2864'],
        [1, 'indoor_skydive_roosendaal','2871'],
        [1, 'naar_de_hoofstad','1074'],
        [1, 'snowworld_amsterdam','2865'],
        [1, 'snowworld_landgraaf','2860'],
        [1, 'snowworld_rucphen-breda','2864'],
        [1, 'snowworld_sw_zoetermeer','2863'],
        [1, 'snowworld_terneuzen','2862'],
      ];
      let headersPool = [];
      headersDB.forEach(el => {
        for(let i=0; i<el[0]; i++){
          let url = '/info?lid='+el[2];
          let target = '_self';
          if(el[2].length == 0){
            url = '/aanvragen';
          }
          if(String(el[1]).substring(0,4) == 'http'){
            url = el[1];
            target = '_blank'
          }
          headersPool.push({ image: el[1], url: url, target: target });
        }
      })
      headersPool = this.shuffle(headersPool);
      let returnArray = [];
      let ssBanner = null;
      while(returnArray.length < 5){
        const chosen = headersPool.shift();
        if(returnArray.filter(el=>el.image == chosen.image).length == 0){
          if(chosen.image == '00_algemeen-ss2024'){
            ssBanner = chosen;
          }else{
            returnArray.push(chosen);
          }
        }
      }
      if(ssBanner != null){
        returnArray.pop();
        returnArray.unshift(ssBanner);
      }
      return returnArray;
    },
    get_route() {
      return this.$route;
    },
    background() {
      var size = this.get_image_size_multiplier > 1 ? '@2x' : '';
      return 'background-image: url(images/banner'+size+'.jpg)';
    },
  },
  methods:{
    shuffle(a) {
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    },
  }
}
</script>
<style>
.slider-indicator-icon{
  border: solid 1px #1C80B5;
  background-color: white !important;
}
.slider-indicator-icon.slider-indicator-active{
  background-color: #1C80B5 !important;
}
.slider-indicators,
.slider-btn{
  z-index:99 !important;
}
@media only screen and (max-width: 800px){
  .slider-indicators{
    display:none;
  }
}
</style>