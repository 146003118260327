<template>
  <div class="details info">
    <Aside />

    <div class="page_container">
      <div class="location-navigation" v-if="!get_window.is_mobile">
        <div class="prev loc-nav" @click="getLocation(-1)"><img src="images/icon-arrow-left.svg" alt=""></div>
        <div class="next loc-nav" @click="getLocation(1)"><img src="images/icon-arrow-right.svg" alt=""></div>
      </div>

      <div class="wrapper">
        <ItemBanner></ItemBanner>

        <div class="page__wrapper" v-if="get_selected_location.name">
          <div class="info__page_title PrimaryColor CustomFont" v-html="get_selected_location.name"></div>
          <div class="info__page_title title_sub" v-html="get_selected_location.city"></div>
          <div class="spacer small"></div>
          <div class="info__page_description" v-html="location_summary"></div>
          <div class="spacer small"></div>
          <div class="info_bar info_in_page" v-if="get_window.is_mobile">
            <div class="SecondaryColor CustomFont" v-if="get_selected_location.opentimes">Openingstijden</div>
            <div v-html="get_selected_location.opentimes"></div>
            <div class="spacer small"></div>
            <div class="SecondaryColor CustomFont">Adres</div>
            <div>{{get_selected_location.address}}</div>
            <div>{{get_selected_location.zipcode}} {{get_selected_location.city}}</div>
            <div class="spacer small"></div>
            <div class="SecondaryColor CustomFont">Website</div>
            <a :href="location_url" target="_blank">{{location_domain}}</a>
            <div class="spacer small"></div>
            <div class="SecondaryColor CustomFont">Telefoon</div>
            <div>{{get_selected_location.phone}}</div>
          </div>
          <div class="spacer medium"></div>
          <div class="buttons-row">
            <LoaderButton :load="false" class="info-pakket-button CustomFont" @action="goToAanvraag"><div>Informatiepakket aanvragen</div></LoaderButton>
          </div>
          <img :src="extraimage" alt="" v-if="get_selected_location.imgextra" class="extra-image">
          <hr />
          <div v-if="suggested_locations.length > 0">
            <div class="text-larger SecondaryColor snicker upperCase" style="margin-top:30px">
              Bekijk ook:
            </div>
            <div class="suggestions">
              <LocationTile
                v-for="location in shuffle(suggested_locations)"
                :key="get_options.location_key + location.id"
                :location="location"
              />
            </div>
          </div>
        </div>
        <div class="page__wrapper" v-else>
          <div class="loaderBlock" style="width: 70%;height: 48px;background:#1C80B5;margin-bottom:40px"></div>
          <div class="loaderBlock" style="width: 20%;height: 20px;background:#000000;margin-bottom:20px"></div>
          <div class="loaderBlock" style="width: 100%;height: 100px;background:#000000;margin-bottom:30px"></div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import Aside from "../components/Aside.vue";
import ItemBanner from "../components/ItemBanner.vue";
import LoaderButton from "../components/LoaderButton.vue";
import LocationTile from "../components/LocationTile.vue";

export default {
  name: "Info",
  components: {
    Aside,
    ItemBanner,
    LoaderButton,
    LocationTile
  },
  props: {},
  data() {
    return {};
  },
  created() {
    if(this.get_all_location_data.length == 0){
      this.get_locations();
    }
  },
  mounted() {
    let query = this.$route.query.lid
    if (query) {
      this.getPageData(query);
    }
    window.scrollTo(0, 0);
  },
  watch: {
    "$route.query.lid"(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.getPageData(newVal);
      }
    }
  },
  computed: {
    ...mapGetters([
      'get_config',
      'get_window',
      'get_options',
      'get_filtered_location_data',
      'get_selected_location',
      'get_all_location_data',
      'get_image_size_multiplier',
    ]),
    location_url(){
       return 'http://'+this.get_selected_location.url;
    },
    location_domain(){
      let url = String(this.get_selected_location.url);
      url = url.replace('www.','');
      url = url.split('/')[0];
      return url;
    },
    has_vrijkaarten(){
      return String(this.get_selected_location.selection_title).indexOf('vrijkaarten') > -1;
    },
    has_rondleiding(){
      return String(this.get_selected_location.selection_title).indexOf('rondleiding') > -1;
    },
    active_category() {
      return this.get_options.active_category;
    },
    banner() {
      let width = 760 * this.get_image_size_multiplier;
      return 'background-image: url("https://cdn.basisam.nl/images/get.php?f='+this.get_selected_location.imglarge+'&w='+width+'&e=jpg&d='+this.get_config.images_cdn+'")';
    },
    extraimage(){
      let width = 969 * this.get_image_size_multiplier;
      return 'https://cdn.basisam.nl/images/get.php?f='+this.get_selected_location.imgextra+'&w='+width+'&e=jpg&d='+this.get_config.images_cdn;
    },
    has_hardlink(){
      if(this.get_selected_location.hasOwnProperty('hardlink')){
        if(this.get_selected_location.hardlink){
          return true;
        }
      }
      return false;
    },
    location_summary(){
      var str = '';
      if(this.get_selected_location.hasOwnProperty('summary')){
        str = this.get_selected_location.summary;
        str = str.replace('<br><ul><br>','<ul>');
        str = str.replace('<br></ul><br>','</ul>');
        str = str.replace('</li><br><li>','</li><li>');
      }
      return str;
    },
    all_similar_locations() {
      let category = this.get_selected_location.category ? this.get_selected_location.category : "Overig";
      return this.get_filtered_location_data(category);
    },
    suggested_locations() {
      return this.all_similar_locations.filter(item => item.id != this.get_selected_location.id);
    }
  },
  methods: {
    ...mapActions({
      get_info: 'get_info',
      get_locations: 'get_locations',
    }),
    getPageData(query){
      this.get_info(query);
    },
    goToAanvraag(){
      this.$router.push({path: '/aanvragen', query: { lid: `${this.get_selected_location.id}` }});
    },
    shuffle(array) {
      return array.sort(() => Math.random() - 0.5).slice(0, 2);
    },
    has_benefit(pricing) {
      return Number(pricing) !== 0;
    },
    has_discount(pricing, percentage) {
      return Number(pricing) !== 2 && Number(percentage) > 0;
    },
    has_two_for_one(pricing) {
      return Number(pricing) === 2;
    },
    get_number_price(amount) {
      return (Number(amount) / 100).toFixed(2);
    },
    getLocation(direction) {
      var all = this.get_all_location_data;
      var curridx = all.findIndex(p => p.id == this.get_selected_location.id);
      var newidx = direction > 0 ? (curridx + 1) : (curridx - 1);

      var nextID;
      if (all.length - 1 < newidx) {
        nextID = all[2].id;
      } else if (newidx < 2) {
        nextID = all[all.length - 1].id;
      } else {
        nextID = all[newidx].id;
      }
      this.$router.push({path: '/info', query: { lid: `${nextID}` }});
    }
  }
};
</script>


